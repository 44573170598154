
import { Component, Vue } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { UnitTypeSubgroup } from '../../entities/unit-type-subgroup.entity';
import unitTypeSubgroupService from '../../services/uniy-type-subgroup.service';

@Component({
  components: {
    SimplePagination,
  },
})
export default class UnitTypeSubgroupListComponent extends Vue {
  private unitTypeSubgroups: UnitTypeSubgroup[] = [];

  private get headers(): any {
    const items = [
      { text: 'Nome', value: 'name', width: '40%' },
      {
        text: 'Ativo',
        value: 'activeText',
      },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ];

    if (this.$permissionAccess.group.isSuperAdmin) {
      items.splice(1, 0, { text: 'Empresa', value: 'company.name', width: '20%' });
    }
    return items;
  }

  private goToFormEdit(unitTypeSubgroup: UnitTypeSubgroup) {
    this.$router.push({
      name: 'unit-type-subgroup-edit',
      params: {
        id: unitTypeSubgroup.id,
      },
    });
  }

  private goToFormCreate() {
    this.$router.push({
      name: 'unit-type-subgroup-create',
    });
  }

  private async getunitTypeSubgroups() {
    const unitTypeSubgroups = await unitTypeSubgroupService.getAllSubgroupsPaginate();
    this.unitTypeSubgroups = unitTypeSubgroups.map(
      (unitTypeSubgroup: UnitTypeSubgroup) => new UnitTypeSubgroup(unitTypeSubgroup),
    );
  }

  public async backPagination() {
    if (!unitTypeSubgroupService.simplePaginationService.prevLink) return;
    const unitTypeSubgroups = await unitTypeSubgroupService.simplePaginationService.prev();
    this.unitTypeSubgroups = unitTypeSubgroups.map(
      (unitTypeSubgroup: UnitTypeSubgroup) => new UnitTypeSubgroup(unitTypeSubgroup),
    );
  }

  public async nextPagination() {
    if (!unitTypeSubgroupService.simplePaginationService.nextLink) return;
    const unitTypeSubgroups = await unitTypeSubgroupService.simplePaginationService.next();
    this.unitTypeSubgroups = unitTypeSubgroups.map(
      (unitTypeSubgroup: UnitTypeSubgroup) => new UnitTypeSubgroup(unitTypeSubgroup),
    );
  }

  public async goToFirst() {
    if (!unitTypeSubgroupService.simplePaginationService.firstLink) return;
    const unitTypeSubgroups = await unitTypeSubgroupService.simplePaginationService.goToFirst();
    this.unitTypeSubgroups = unitTypeSubgroups.map(
      (unitTypeSubgroup: UnitTypeSubgroup) => new UnitTypeSubgroup(unitTypeSubgroup),
    );
  }

  private async created() {
    this.getunitTypeSubgroups();
  }
}
