import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { UnitTypeSubgroup } from '../entities/unit-type-subgroup.entity';

class UnitTypeSubgroupService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: UnitTypeSubgroup) {
    return http.post('/unit-type-subgroup', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: UnitTypeSubgroup) {
    return http
      .put(`/unit-type-subgroup/${payload.id}`, payload)
      .then(({ data }: AxiosResponse) => data);
  }

  public delete(unitTypeSubgroupId: string) {
    return http
      .delete(`/unit-type-subgroup/${unitTypeSubgroupId}`)
      .then(({ data }: AxiosResponse) => data);
  }

  public getAllSubgroups() {
    return http.get('/unit-type-subgroup').then(({ data }: AxiosResponse) => data.data);
  }

  public getAllSubgroupsPaginate() {
    return http
      .get('/unit-type-subgroup', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getSubgroupsByCompanyId(id: string) {
    return http
      .get('/unit-type-subgroup', {
        params: {
          company_id: id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getUnitTypeSubgroupById(id: string) {
    return http.get(`/unit-type-subgroup/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new UnitTypeSubgroupService(simplePaginationService);
