import unitTypeSubgroupService from '../services/uniy-type-subgroup.service';

export class UnitTypeSubgroup {
  public id: string;

  public name: string;

  public active: boolean | number;

  public company_id: string | undefined;

  public company: any;

  public has_relationship: boolean | number = false;

  constructor(data: any = {}) {
    this.id = data.id || '';
    this.name = data.name || '';
    this.company_id = data.company ? data.company.id : undefined;
    this.company = data.company;
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
    };
  }

  public save() {
    return unitTypeSubgroupService.create(this.format());
  }

  public update() {
    return unitTypeSubgroupService.update(this.format());
  }

  public delete() {
    return unitTypeSubgroupService.delete(this.id);
  }
}
